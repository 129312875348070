<template>
  <div id="payrolls">
    <v-container>
      <v-layout row wrap justify="start">
        <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
          <v-btn
            style="float: left"
            outlined
            small
            :color="$store.state.secondaryColor"
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="mb-3">
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-autocomplete
            dense
            solo
            class="caption"
            :label="$t('select month')"
            item-value="id"
            :items="months"
            v-model="selectedMonth"
            @change="onSelectMonth"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-autocomplete
            dense
            solo
            class="caption"
            :label="$t('Select year')"
            item-value="id"
            :items="years"
            v-model="selectedYear"
            @change="onSelectYear(selectedYear)"
          ></v-autocomplete>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-tooltip bottom v-if="totalPayroll">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                class="gradients"
                @click="printPage()"
              >
                <v-icon small left>mdi-download</v-icon>
                {{ $t("download") }}
              </v-btn></template
            >
            <span>{{ $t("download your monthly payroll") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" dark small class="gradients">
                <v-icon small left disabled>mdi-download</v-icon>
                {{ $t("download") }}
              </v-btn>
            </template>
            <span>{{ $t("please wait for calculations") }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>

      <!-- ####################### NEW payroll ##################### -->

      <div v-show="payroll">
        <v-card class="grey lighten-3 pa-5">
          <div class="container">
            <div class="body" id="print" style="padding: 30px">
              <div class="row center">
                <div class="column">
                  <span class="bold-text">{{
                    getBusinessName(this.$store.getters.getCurrentBusinessTemp)
                  }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ building }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ floor }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ street }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ county + " " + country }}</span>
                </div>
              </div>

              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="font-weight-black">{{ title }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row left" v-if="selectedYear && selectedMonth">
                <div class="column-half">
                  <span class="bold-text" style="font-size: 15px">
                    {{ $t("month and year") }}:
                    {{ selectedMonth + " " + selectedYear }}
                  </span>
                </div>
                <div class="column-half right">
                  <span class="bold-text" style="font-size: 15px">
                    {{ $t("total payroll") }}:
                    {{ currencyFormat(totalPayroll) }}
                  </span>
                </div>
              </div>

              <table id="monthlyPayrollTable" style="object-fit: contain">
                <thead>
                  <tr>
                    <th>{{ $t("employee Id") }}</th>
                    <th>{{ $t("name") }}</th>
                    <th>{{ $t("basic pay") }}</th>
                    <th>{{ $t("overtime") }}</th>
                    <th>{{ $t("total earnings") }}</th>
                    <th>{{ $t("total gross pay") }}</th>
                    <th>{{ $t("total deductions") }}</th>
                    <th>{{ $t("total paye") }}</th>
                    <th>{{ $t("net pay") }}</th>
                  </tr>
                </thead>
                <tbody v-for="payroll in payroll" :key="payroll.id">
                  <tr>
                    <td>{{ payroll.employeeId }}</td>
                    <td>
                      {{
                        payroll.names.first +
                        " " +
                        payroll.names.middle +
                        " " +
                        payroll.names.last
                      }}
                    </td>
                    <td>
                      {{ payroll.basicPay }}
                    </td>

                    <td>{{ payroll.overtimePay }}</td>
                    <td>{{ payroll.totalEarnings }}</td>
                    <td>
                      {{ payroll.grossPay }}
                    </td>
                    <td>{{ payroll.totalDeductions }}</td>
                    <td>{{ payroll.calculatedPaye }}</td>
                    <td>{{ payroll.netPay }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- ################## -->
        </v-card>
      </div>
      <!-- ############################### -->

      <v-snackbar
        v-model="snackErrorMonth"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("please also select a month") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorMonth = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorYear"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("please also select a year") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorYear = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { printer } from "@/plugins/functions";
import { format } from "date-fns";

import db from "@/plugins/fb";

export default {
  data: () => ({
    selectedBusiness: "",
    sales: [],
    payroll: [],
    totalPayroll: 0,
    employeeIds: [],
    monthSales: [],
    filteredOvertimes: [],
    selectedYear: format(new Date(), "yyyy"),
    paye: "",
    netPay: "",
    monthlyPayRollEarnings: [],
    monthlyPayRollDeductions: [],
    selectedMonth: format(new Date(), "MMMM"),
    totalDeductions: "",
    totalEarnings: "",
    building: "",
    totalOvertime: "",
    floor: "",
    county: "",
    country: "",
    totalPayrollTwo: [],
    grossPay: "",
    street: "",
    snackErrorMonth: false,
    snackErrorYear: false,
    employee: {
      name: {},
    },
    detailedReport: [],
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    months() {
      return this.$store.getters.getMonths;
    },
    years() {
      return this.$store.getters.getYears;
    },

    selected() {
      if (this.$store.getters.getCurrentBusiness) {
        return this.$store.getters.getCurrentBusiness;
      } else {
        return this.$store.getters.getCurrentBusinessTemp;
      }
    },

    today() {
      return format(new Date(), "yyyy-MM-dd");
    },

    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },
    title() {
      return "Monthly payroll";
    },
    reportHeader() {
      return [
        { title: this.$t("business name"), value: "" },
        { title: this.$t("email"), value: "" },
        { title: this.$t("date of report"), value: "" },
        { title: this.$t("report period"), value: "" },
      ];
    },

    dataSummary() {
      return [{ title: this.$t("total sales"), value: "" }];
    },
  },

  created() {
    this.getOvertimePay();
    this.getPayrollDetails();
  },

  methods: {
    printer,

    goBack() {
      window.history.back();
    },

    onSelectYear(year) {
      this.selectedYear = year;
      if (!this.selectedMonth) {
        this.snackErrorMonth = true;
      } else {
        this.filterPayroll();
      }
    },

    //function called when month is changed
    onSelectMonth(month) {
      this.selectedMonth = month;
      if (!this.selectedYear) {
        this.snackErrorYear = true;
      } else {
        this.filterPayroll();
      }
    },

    getOvertimePay() {
      this.overtimes = [];
      this.totalOvertime = 0;

      db.collection("overtimes")
        .where("businessId", "==", this.$store.getters.getCurrentBusinessTemp)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.overtimes.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },

    getPayrollDetails() {
      db.collection("payroll")
        .where("businessId", "==", this.selected)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.payroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.getBusinessName(this.$store.getters.getCurrentBusinessTemp);
          this.filterPayroll();
        });
    },

    filterPayroll() {
      this.payroll.forEach((payroll) => {
        var monthlyEarnings = payroll.monthlyPay.earnings.filter(
          (item) =>
            format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
            format(item.dateTime.toDate(), "yyyy") == this.selectedYear
        );

        var monthlyDeductions = payroll.monthlyPay.deductions.filter(
          (item) =>
            format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
            format(item.dateTime.toDate(), "yyyy") == this.selectedYear
        );

        var mappedEarnings = monthlyEarnings.map((i) =>
          Number(i.earningsAmount)
        );

        var mappedDeductions = monthlyDeductions.map((i) =>
          Number(i.deductionAmount)
        );

        var totalEarnings =
          mappedEarnings.length > 1
            ? mappedEarnings.reduce((prev, next) => prev + next)
            : 0;

        var totalDeduction =
          mappedDeductions.length > 1
            ? mappedDeductions.reduce((a, b) => a + b)
            : 0;

        payroll.totalEarnings = totalEarnings ? totalEarnings : 0;
        payroll.totalDeductions = totalDeduction ? totalDeduction : 0;
        this.overTimeValue(payroll);
      });
    },
    overTimeValue(payroll) {
      var overtimedata = this.overtimes.filter(
        (item) =>
          item.employeeId == payroll.employeeId &&
          format(item.overtimeDate.toDate(), "MMMM") == this.selectedMonth &&
          format(item.overtimeDate.toDate(), "yyyy") == this.selectedYear
      );

      var mappedOvertime = overtimedata.map((item) => item.overtimePay);
      var totalOvertime =
        mappedOvertime.length >= 1 ? mappedOvertime.reduce((a, b) => a + b) : 0;

      payroll.overtimePay = totalOvertime;

      this.calculateTotalEarnings(payroll);
    },

    calculateTotalEarnings(payroll) {
      if (payroll) {
        var tt = [];
        tt = payroll.monthlyPay.earnings.filter(
          (item) =>
            format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
            format(item.dateTime.toDate(), "yyyy") == this.selectedYear
        );

        let expAmounts = tt.map((item) => Number(item.earningsAmount));

        let expsumAmounts =
          expAmounts.length >= 1
            ? expAmounts.reduce((prev, next) => prev + next)
            : expAmounts[0] != null
            ? expAmounts[0]
            : 0;

        payroll.totalEarnings = expsumAmounts;
        this.calculateTotalDeductions(payroll);
      }
    },

    calculateTotalDeductions(payroll) {
      if (payroll) {
        var tt = [];
        tt = payroll.monthlyPay.deductions.filter(
          (item) =>
            format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
            format(item.dateTime.toDate(), "yyyy") == this.selectedYear
        );

        let dedAmounts = tt.map((item) => Number(item.deductionAmount));
        let dedsumAmounts =
          dedAmounts.length >= 1
            ? dedAmounts.reduce((prev, next) => prev + next)
            : dedAmounts[0] != null
            ? dedAmounts[0]
            : 0;
        payroll.totalDeductions = dedsumAmounts;

        this.calculateGrossPay(payroll);
      }
    },

    calculateGrossPay(payroll) {
      let grossPay =
        Number(payroll.basicPay) + payroll.overtimePay + payroll.totalEarnings;
      payroll.grossPay = grossPay;

      this.calculatePAYE(payroll);
    },

    calculatePAYE(payroll) {
      var calculatedPAYE = 0;
      calculatedPAYE = Math.round(
        (payroll.paye / 100) *
          (Number(payroll.grossPay) +
            payroll.totalEarnings -
            payroll.totalDeductions)
      );
      payroll.calculatedPaye = calculatedPAYE;

      this.calculateNetPay(payroll);
    },

    calculateNetPay(payroll) {
      //reset variables
      var netPay = 0;

      netPay = Math.round(
        Number(payroll.grossPay) +
          payroll.totalEarnings -
          (payroll.totalDeductions + payroll.calculatedPaye)
      );
      //calculate total amount of payroll
      this.totalPayroll += netPay;
      payroll.netPay = netPay;
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },
    // getEmployeeDetails(data) {
    //   this.employee.name.first = data.names.first;
    //   this.employee.name.middle = data.names.middle;
    //   this.employee.name.last = data.names.last;
    //   this.employee.basicPay = data.basicPay;
    //   this.employee.paye = data.paye;

    //   this.filterPayroll();
    // },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.getBusinessLocation(id);
      return bizId.businessName;
    },

    getBusinessLocation(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.building = bizId.address.building;
      this.floor = bizId.address.no;
      this.county = bizId.address.county;
      this.country = bizId.address.country;
      this.street = bizId.address.street;
    },

    printPage() {
      this.printer("print", "payroll");
    },
    formatDate(val) {
      return format(val, "yyyy-MM-dd");
    },

    formatDateMinuteTime(val) {
      return format(val, "hh:mm aa");
    },
  },
};
</script>

<style scoped>
.printOut {
  border: 1px solid #eee;
  min-height: 29.7cm;
  width: 21cm;
  padding: 2cm;
  background: #fff;
}
.payrollContents {
  font-family: "Courier New", monospace;
}
.headingRow {
  font-size: 13px;
  color: #aa9494;
  background-color: black;
}
.captionStyle {
  font-size: 13px;
}

.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px dashed #000000;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.border {
  margin-top: 35px;
  border-style: dotted;
}

.body {
  font-family: "Courier New", monospace;
  padding: 20px;
}
.body .container {
  background: white;
  color: grey (126, 126, 126);
  font-size: 12px;
  padding: 40px;
}
.bold-text {
  font-weight: bold;
}
.bolder-text {
  font-weight: bolder;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
  margin-top: 2px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;

  margin-top: 4px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
  margin-top: 2px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}

#monthlyPayrollTable {
  font-family: "Courier New", monospace;
  border-collapse: collapse;
  width: 100%;
}

#monthlyPayrollTable td,
#monthlyPayrollTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#monthlyPayrollTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#monthlyPayrollTable tr:hover {
  background-color: #ddd;
}

#monthlyPayrollTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #37474f;
  color: white;
}
</style>
