function digits(event) {
  let keyCode = event.keyCode ? event.keyCode : event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
    // 46 is dot 43 is +
    event.preventDefault();
  }
}
function printer(div, title) {
  // Get HTML to print from element

  var printableArea = document.getElementById(div).innerHTML;

  // Get all stylesheets HTML
  let stylesHtml = "";
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style'),
  ]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    "",
    "",
    "left=100,top=100,width=500,height=900,toolbar=0,scrollbars=0,status=0"
  );

  WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${printableArea}
  </body>
</html>`);
  WinPrint.document.title = title;
  WinPrint.document.close(); //missing code
  WinPrint.focus;
  WinPrint.print();

  // var fullContent = document.body.innerHTML;
  // var printableArea = document.getElementById(div).innerHTML;
  // document.body.innerHTML = printableArea;
  // window.print();
  // document.close();
  // // location.reload();
  // document.body.innerHTML = fullContent;
}

export { digits, printer };
